



















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { BBadge, BLink, BFormInput, BSidebar, VBToggle, BPagination, BTable, BCard, BSpinner, BMedia, BAvatar, BDropdown, BDropdownItem, BRow, BCol } from 'bootstrap-vue'
import VSelect from 'vue-select'

import { defaultPagination, Pagination } from '@/models/General'
import { Action } from '@/store/decorators'
import { UserModule } from '@/store/modules/userModule'
import { avatarText } from '@core/utils/filter'
import AdminEditUserSidebar from './AdminEditUserSidebar.vue'
import { UserRoleEnums } from '@/enums/User'

import {
  defaultParamsPayload,
  ModelPayload,
  ParamsPayload
} from '@/models/Payloads'
import { mixins } from 'vue-class-component'
import MixinFunctions from '@/mixins/mixinFunctions'

@Component({
  name: 'AdminUsersList',
  components: {
    BBadge,
    BLink,
    BFormInput,
    BPagination,
    BTable,
    BCard,
    BSpinner,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BSidebar,
    AdminEditUserSidebar,
    VSelect
  },
  directives: {
    'b-toggle': VBToggle
  }
})
export default class AdminUsersList extends mixins(Vue, MixinFunctions) {
  @Action(UserModule, 'fetchAdminUsers') private fetchAdminUsers!: (payload: ModelPayload) => Promise<Pagination>

  $refs!: { refAdminUsersTable }

  private fields: object = [
    { key: 'user' },
    { key: 'email' },
    { key: 'roles' },
    { key: 'account_status', label: 'Status' },
    { key: 'actions', thStyle: { width: '1%' } }
  ]

  private userRole: string = UserRoleEnums.Admin
  private adminUserPagination: Pagination = defaultPagination()
  private avatarText: object = avatarText
  private userData: object = {}
  private paramsPayload: ParamsPayload = defaultParamsPayload()
  private currentPage: number = 1
  private perPage: number = 10
  private perPageOptions: number[] = [10, 25, 50, 100]
  private totalData: number = 0
  private searchQuery: string = ''
  private searchQueryTimeout: any = null

  private created () {
    // TODO need to load the query string at the first load
    this.paramsPayload = this.loadQueryString(this.paramsPayload)
  }
  private passAdminUserData (userData) {
    this.userData = userData
  }

  private async loadAdminUsers () {
    try {
      this.adminUserPagination = await this.fetchAdminUsers({
        params: this.paramsPayload
      })
      this.totalData = this.adminUserPagination.total

      return this.adminUserPagination.data
    } catch (error) {
      // console.error(error)
    }
  }

  get dataMeta () {
    const localItemsCount = this.$refs.refAdminUsersTable ? this.$refs.refAdminUsersTable.localItems.length : 0

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to: this.perPage * (this.currentPage - 1) + localItemsCount,
      of: this.totalData
    }
  }

  @Watch('perPage')
  @Watch('currentPage')
  private refreshTable () {
    this.paramsPayload.perPage = this.perPage
    this.paramsPayload.page = this.currentPage
    clearTimeout(this.searchQueryTimeout)
    this.$refs.refAdminUsersTable.refresh()
  }

  @Watch('searchQuery')
  private onSearchQuery () {
    this.paramsPayload.q = this.searchQuery
    clearTimeout(this.searchQueryTimeout)

    this.searchQueryTimeout = setTimeout(() => {
      this.refreshTable()
    }, 1000)
  }
}
