












































































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  BContainer,
  BCardBody,
  BCardTitle,
  BCardText,
  BCard,
  BCol,
  BRow,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'

@Component({
  name: 'AdminEditUserSidebar',
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BContainer,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea
  }
})
export default class AdminEditUserSidebar extends Vue {
  @Prop({ default: 'Customer' }) userRole: string
  @Prop() userData: object
}
